import React, { useLayoutEffect, useEffect, useRef } from 'react';
import { isServer } from "@cargo/common/helpers";

export const ElementScrollEffect = ({ elementReferences, authenticated, keyframes }) => {
    const ticking = useRef(false);
    // Object to track the state of each element that is initially in view
    const elementsState = useRef({});

    // Default keyframes. Goes from 0% (bottom) to 100% (top) because
    // it's easier to initialize on 0 than array.length - 1.
    const defaultKeyframes = [
        {
            percentage: 0,
            properties: {
                opacity: 1,
                // transform: {
                //     translateY: { value: 10, unit: 'em' },
                // },
            },
        },
        {
            percentage: 42,
            properties: {
                opacity: 1,
                // transform: {
                //     translateY: { value: 0, unit: 'em' },
                // },
            },
        },
        {
            percentage: 70,
            properties: {
                opacity: 1,
                // transform: {
                //     translateY: { value: 0, unit: 'em' },
                // },
            },
        },
        {
            percentage: 100,
            properties: {
                opacity: 0,
                // transform: {
                //     translateY: { value: 0, unit: 'em' },
                // },
            },
        },
    ];

    const animationKeyframes = keyframes || defaultKeyframes;

    const fadeOnScroll = () => {
        const viewportHeight = window.innerHeight;
        const elements = [];

        // Batch DOM reads
        Object.keys(elementReferences).forEach((key) => {
            const el = elementReferences[key]?.current;
            if (!el) return;

            // Read measurements before applying styles
            const rect = el.getBoundingClientRect();
            const elementHeight = rect.height;

            elements.push({ key, el, rect, elementHeight });
        });

        // Batch calculations and DOM writes
        elements.forEach(({ key, el, rect, elementHeight }) => {

            const elementEnd = -elementHeight;
            // Calculate scroll progress.
            // viewportHeight - rect.top is how far the element has moved into the viewport from the bottom.
            // viewportHeight - elementEnd the total distance the element travels:
            //                                 from the top edge entering at the bottom
            //                                 to the bottom edge exiting at the top.
            const scrollProgress = (viewportHeight - rect.top) / (viewportHeight - elementEnd);
            const clampedProgress = Math.min(Math.max(scrollProgress, 0), 1);
            const percentageProgress = clampedProgress * 100;

            // Find keyframes relevant to the element's current scroll position
            let keyframe1, keyframe2;
            for (let i = 0; i < animationKeyframes.length - 1; i++) {
                if (
                    percentageProgress >= animationKeyframes[i].percentage &&
                    percentageProgress <= animationKeyframes[i + 1].percentage
                ) {
                    keyframe1 = animationKeyframes[i];
                    keyframe2 = animationKeyframes[i + 1];
                    break;
                }
            }

            // Handle progress when element is not within keyframe buffer
            if (!keyframe1 || !keyframe2) {
                if (percentageProgress < animationKeyframes[0].percentage) {
                    keyframe1 = keyframe2 = animationKeyframes[0];
                } else {
                    keyframe1 = keyframe2 = animationKeyframes[animationKeyframes.length - 1];
                }
            }

            const localProgress = (percentageProgress - keyframe1.percentage) / (keyframe2.percentage - keyframe1.percentage);
            const clampedLocalProgress = isFinite(localProgress) ? Math.min(Math.max(localProgress, 0), 1) : 0;

            // Interpolate properties
            const interpolatedProperties = {};

            const allProperties = new Set([
                ...Object.keys(keyframe1.properties || {}),
                ...Object.keys(keyframe2.properties || {}),
            ]);

            allProperties.forEach((prop) => {
                const startValue = keyframe1.properties[prop];
                const endValue = keyframe2.properties[prop];

                if (prop === 'transform') {
                    // Handle transform functions
                    const interpolatedTransform = interpolateTransform(
                        startValue,
                        endValue,
                        clampedLocalProgress
                    );
                    if (interpolatedTransform) {
                        interpolatedProperties[prop] = interpolatedTransform;
                    }
                } else {
                    // Handle other properties
                    const interpolatedValue = interpolateGenericProperty(
                        startValue,
                        endValue,
                        clampedLocalProgress
                    );
                    if (interpolatedValue !== null) {
                        interpolatedProperties[prop] = interpolatedValue;
                    }
                }
            });

            // Get element state
            const elementState = elementsState.current[key];

            // Apply styles
            Object.keys(interpolatedProperties).forEach((prop) => {
                if (prop === 'transform') {
                    const elForTransform = el.firstElementChild;

                    // If the element was initially in view and hasn't exited yet, skip applying the transform
                    if (!(elementState.initiallyInView && !elementState.hasExitedViewport)) {
                        // Also, if the element is back at the top of the viewport, reset transform
                        if (elementState.initiallyInView && rect.top >= 0 && rect.bottom <= viewportHeight) {
                            elForTransform.style.transform = '';
                            // Reset hasExitedViewport to false
                            elementState.hasExitedViewport = false;
                        } else {
                            elForTransform.style.transform = interpolatedProperties[prop];
                        }
                    }
                } else {
                    el.style[prop] = interpolatedProperties[prop];
                }
            });

            // Update element state if necessary
            if (elementState.initiallyInView) {
                if (!elementState.hasExitedViewport) {
                    // Check if the element has fully exited the viewport
                    if (rect.bottom <= 0 || rect.top >= viewportHeight) {
                        // The element has fully exited the viewport
                        elementState.hasExitedViewport = true;
                    }
                } else {
                    // If the element is fully back in view at the top, reset hasExitedViewport
                    if (rect.top >= 0 && rect.bottom <= viewportHeight) {
                        elementState.hasExitedViewport = false;
                    }
                }
            }
        });
    };

    const interpolateTransform = (startValue, endValue, progress) => {
        const startTransforms = startValue || {};
        const endTransforms = endValue || {};

        const transformFunctions = new Set([
            ...Object.keys(startTransforms),
            ...Object.keys(endTransforms),
        ]);

        const interpolatedTransforms = [];

        transformFunctions.forEach((key) => {
            const initialValue = startTransforms[key];
            const finalValue = endTransforms[key];

            const interpolatedFuncValue = interpolateTransformFunction(
                initialValue,
                finalValue,
                progress
            );

            if (interpolatedFuncValue !== null) {
                interpolatedTransforms.push(`${key}(${interpolatedFuncValue})`);
            }
        });

        return interpolatedTransforms.join(' ');
    };

    const interpolateTransformFunction = (initialValue, finalValue, progress) => {

        if (initialValue === undefined && finalValue === undefined) {
            // No values. Cannot interpolate.
            return null;
        }

        if (initialValue === undefined) {
            // Start value is missing, use end value
            return formatValueWithUnit(finalValue);
        }

        if (finalValue === undefined) {
            // End value is missing, use start value
            return formatValueWithUnit(initialValue);
        }

        // Both values are defined and units match
        if (
            typeof initialValue.value === 'number' &&
            typeof finalValue.value === 'number' &&
            initialValue.unit === finalValue.unit
        ) {
            const interpolatedValue = initialValue.value + (finalValue.value - initialValue.value) * progress;

            return `${interpolatedValue}${initialValue.unit}`;
        } else {
            // console.log('Cannot interpolate transform functions with mismatched units or non-numeric values.');
            return null;
        }
    };

    const interpolateGenericProperty = (startValue, endValue, progress) => {
        if (startValue === undefined && endValue === undefined) {
            return null;
        }

        if (startValue === undefined) {
            return formatValueWithUnit(endValue);
        }

        if (endValue === undefined) {
            return formatValueWithUnit(startValue);
        }

        if (typeof startValue === 'number' && typeof endValue === 'number') {
            const interpolatedValue = startValue + (endValue - startValue) * progress;
            return interpolatedValue;
        } else if (
            typeof startValue === 'object' &&
            typeof endValue === 'object' &&
            typeof startValue.value === 'number' &&
            typeof endValue.value === 'number' &&
            startValue.unit === endValue.unit
        ) {
            const interpolatedValue = startValue.value + (endValue.value - startValue.value) * progress;
            return `${interpolatedValue}${startValue.unit}`;
        } else if ( typeof startValue === 'string' && typeof endValue === 'string' ){
            // Choose value based on progress
            return progress < 0.5 ? startValue : endValue;
        } else {
            return null;
        }
    };

    const formatValueWithUnit = (value) => {
        if (
            typeof value === 'object' &&
            value !== null &&
            typeof value.value !== 'undefined'
        ) {
            return `${value.value}${value.unit || ''}`;
        }
        return value;
    };

    const handleScroll = () => {
        if (!ticking.current) {
            requestAnimationFrame(() => {
                fadeOnScroll();
                ticking.current = false;
            });
            ticking.current = true;
        }
    };

    if(!isServer) {
        useLayoutEffect(() => {
            // Initialize elementsState and apply initial styles
            Object.keys(elementReferences).forEach((key) => {
                const el = elementReferences[key]?.current;
                if (el) {
                    const rect = el.getBoundingClientRect();
                    const viewportHeight = window.innerHeight;

                    // Determine if the element is initially in the viewport
                    const inViewport = rect.bottom > 0 && rect.top < viewportHeight;

                    // Initialize element state
                    elementsState.current[key] = {
                        initiallyInView: inViewport,
                        hasExitedViewport: false,
                    };

                    const elForTransform = el.firstElementChild;

                    el.style.willChange = 'opacity';
                    // elForTransform.style.willChange = 'transform';

                    const initialKeyframe = animationKeyframes[0];
                    const initialProperties = initialKeyframe.properties || {};
                    Object.keys(initialProperties).forEach((prop) => {
                        if (prop === 'transform') {
                            const transforms = initialProperties[prop];
                            const transformStrings = [];
                            Object.keys(transforms).forEach((funcName) => {
                                const funcValue = transforms[funcName];
                                transformStrings.push(`${funcName}(${formatValueWithUnit(funcValue)})`);
                            });
                            // Apply initial transform styles
                            // Only apply if the element was not initially in view
                            if (!inViewport) {
                                elForTransform.style.transform = transformStrings.join(' ');
                            } else {
                                // Ensure no transform is applied initially
                                elForTransform.style.transform = '';
                            }
                        } else {
                            el.style[prop] = formatValueWithUnit(initialProperties[prop]);
                        }
                    });
                }
            });

            // Initial call to set styles based on current scroll position
            fadeOnScroll();

            window.addEventListener('scroll', handleScroll, { passive: true });

            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }, [elementReferences, animationKeyframes]);
    }

    useEffect(() => {
        // Observe size changes to elements
        const resizeObserver = new ResizeObserver(() => {
            fadeOnScroll();
        });

        Object.values(elementReferences).forEach((ref) => {
            if (ref.current) {
                resizeObserver.observe(ref.current);
            }
        });

        return () => {
            resizeObserver.disconnect();
        };
    }, [elementReferences]);

    useEffect(() => {
        // Clear styles when authenticated
        if (authenticated) {
            Object.keys(elementReferences).forEach((key) => {
                const el = elementReferences[key]?.current;
                if (el) {
                    const elForTransform = el.firstElementChild;
                    el.style.cssText = ''; // Clear all inline styles
                    if(elForTransform) {
                        elForTransform.style.cssText = ''; // Clear all inline styles
                    }
                }
            });
        }
    }, [authenticated, elementReferences]);

    return null;
};
