import { withRouter, NavLink } from 'react-router-dom';

export const LinkButton = ({ label, route, checked, active, article }) => (

    <NavLink
        exact
        to={route}
        className={`button-link${(active && article) || (checked && !article) ? ' selected' : ''}`}
        onDragStart={(e) => { e.preventDefault(); }}
        onContextMenu={(e) => { e.preventDefault(); }}
    >
        {checked ? (<>{'\uE001'}{'\u00A0'}</>) : null}{label}
    </NavLink>
);